import CryptoJS from "crypto-js"

export function encrypt(content) {
	let string = localStorage.getItem("token").match(/dQJ9Lc(\S*)dQJ9Lc/)[1]
	let key = CryptoJS.enc.Utf8.parse(string)
	let iv = CryptoJS.enc.Utf8.parse(string.split("").reverse().join(""))
	let encrypted = CryptoJS.AES.encrypt(content, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	return encrypted.toString();
}
export function decrypt(content) {
	let string = localStorage.getItem("token").match(/dQJ9Lc(\S*)dQJ9Lc/)[1]
	let key = CryptoJS.enc.Utf8.parse(string)
	let iv = CryptoJS.enc.Utf8.parse(string.split("").reverse().join(""))
	let decrypt = CryptoJS.AES.decrypt(content, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}