module.exports = {
  // 判断是否为JSON字符串
  isJson(params) {
    if (typeof params == "string") {
      try {
        if (typeof JSON.parse(params) == "object" && JSON.parse(params)) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
    return false;
  },
  // 判断是否为正整数
  isPositiveInteger(params) {
    return /^[1-9]\d*$/.test(params);
  },
  // 判断是否为手机号码
  isPhoneNumber(params) {
    return /^0?1[3|4|5|6|7|8][0-9]\d{8}$/.test(params);
  },
  // 转换日期
  convertDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1: "0" + (date.getMonth() + 1);
    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    return year + "-" + month + "-" + day;
  },
  // 转换时间
  convertTime(time) {
    let hour = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
    let minute =time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
    let second =
      time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
    return hour + ":" + minute + ":" + second;
  },
};
