import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
  path: "/",
  redirect: "login"
},
{
  path: "/login",
  name: "login",
  component: () => import("../views/LoginView.vue"),
  meta: {
    title: "登录",
  },
},
{
  path: "/home",
  name: "home",
  redirect: "/home",
  component: () => import("../views/HomeView.vue"),
  children: [
    {
      path: "/index",
      name: "index",
      component: () => import("../views/index/IndexView.vue"),
      meta: {
        title: "首页",
      },
    },
    {
      path: "/type",
      name: "type",
      component: () => import("../views/type/TypeView.vue"),
      meta: {
        title: "类型管理",
      },
    },
    {
      path: "/information",
      name: "information",
      component: () => import("../views/information/InformationView.vue"),
      meta: {
        title: "演出管理",
      },
    },
    {
      path: "/carousel",
      name: "carousel",
      component: () => import("../views/carousel/CarouselView.vue"),
      meta: {
        title: "轮播管理",
      },
    },
    {
      path: "/order",
      name: "order",
      component: () => import("../views/order/OrderView.vue"),
      meta: {
        title: "订单管理",
      },
    },
    {
      path: "/video",
      name: "video",
      component: () => import("../views/video/VideoView.vue"),
      meta: {
        title: "视频管理",
      },
    },
    {
      path: "/publicize",
      name: "publicize",
      component: () => import("../views/publicize/PublicizeView.vue"),
      meta: {
        title: "视频管理",
      },
    },
    {
      path: "/customer",
      name: "customer",
      component: () => import("../views/customer/CustomerView.vue"),
      meta: {
        title: "客服管理",
      },
    },
    {
      path: "/cents",
      name: "cents",
      component: () => import("../views/cents/CentsView.vue"),
      meta: {
        title: "分佣管理",
      },
    },
    {
      path: "/perform",
      name: "perform",
      component: () => import("../views/perform/PerformView.vue"),
      meta: {
        title: "演出管理",
      },
    },
    {
      path: "/message",
      name: "message",
      component: () => import("../views/message/MessageView.vue"),
      meta: {
        title: "短信管理",
      },
    },
  ],
},
{
  path: "/log",
  name: "log",
  component: () => import("../views/LogView.vue"),
  meta: {
    title: "日志",
  },
},
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == "login") {
    localStorage.removeItem('token')
    next()
  } else {
    if (localStorage.getItem("token")) {
      next()
    } else {
      router.push("/login");
    }
  }
})

export default router