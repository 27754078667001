import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import Viewer from "v-viewer"
import ElementUI from "element-ui"

import "default-passive-events"
import "viewerjs/dist/viewer.css"
import "element-ui/lib/theme-chalk/index.css"

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
}).$mount("#app")

Vue.config.productionTip = false
Vue.config.silent = true
Vue.config.devtools = false

Vue.use(ElementUI)
Vue.use(Viewer)

Viewer.setDefaults({
  movable: false,
  title: false,
})

Vue.prototype.api = require("../src/plugins/api.js")
Vue.prototype.tools = require("../src/plugins/tools.js")

if (process.env.NODE_ENV !== 'development') {
  console.log(`%c欢迎使用国潮音乐嘉年华票务管理系统！\n%cV3.6.2`, 'font-size:30px;color: #000000;font-weight:600;font-family:"隶书";', 'font-size:16px;color: #409EFF;');
  console.log = function () { }
  console.info = function () { }
  console.warn = function () { }
  console.error = function () { }
  console.debug = function () { }
}