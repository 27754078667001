<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
  },
  methods: {

  },

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /* touch-action: none; */
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
form,
img,
video {
  box-sizing: border-box;
}

img {
  vertical-align: top;
}
</style>