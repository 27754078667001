import axios from "axios"
import tools from "./tools";
import router from "@/router";
import { Message } from "element-ui";
import { MessageBox } from "element-ui";

let aes = require("./aes")
let service, params, encrypt, url

//1. 创建新的axios实例
service = axios.create({
    baseURL: "https://gc.njyfkj.cn/api/",
    // baseURL: "http://10.10.10.26:6688/",
    timeout: 600000,
})

// 2.请求拦截器
service.interceptors.request.use(config => {
    config.headers = {
        "Content-Type": "application/json",
        "Infol-Token": localStorage.getItem("token") ? localStorage.getItem("token") : "",
        ...config.body,
    }
    encrypt = (config.encrypt == false) ? config.encrypt : true
    params = config.data ? config.data : ""
    url = config.url.replace(config.baseURL, "")
    if (encrypt) {
        config.data = config.data ? aes.encrypt(JSON.stringify(config.data)) : ""
    } else {
        config.data = config.data ? config.data : ""
    }
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    if (response.data.code == 0) {
        return Promise.resolve({
            url: url,
            params: params,
            code: response.data.code,
            data: tools.isJson(response.data.data ? (encrypt ? aes.decrypt(response.data.data) : response.data.data) : "") ? JSON.parse(response.data.data ? (encrypt ? aes.decrypt(response.data.data) : response.data.data) : "") : response.data.data ? (encrypt ? aes.decrypt(response.data.data) : response.data.data) : "",
        })
    } else {
        return Promise.resolve({
            url: url,
            params: params,
            code: response.data.code,
            msg: response.data.msg,
        })
    }
}, error => {
    if (error == "Network Error") {
        Message("当前网络异常，请检查您的网络");
    }
    else if (error == "Error: timeout of 60000ms exceeded") {
        Message("当前服务器异常，请您稍后再尝试");
    }
    else {
        if (error.response.data.code) {
            if (error.response.data.msg == "token已经过期，无权限访问") {
                localStorage.removeItem("token")
                router.push("/login");
                MessageBox("当前登录已过期，请您重新登录", "温馨提示", {
                    confirmButtonText: "确定",
                })
            } else {
                return Promise.resolve({
                    url: url,
                    params: params,
                    code: error.response.data.code,
                    msg: error.response.data.msg,
                })
            }
        } else {
            Message("当前服务器异常，请您稍后再尝试");
            return Promise.resolve(error.response.data)
        }
    }
})

//4.导入文件
export default service